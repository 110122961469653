import styled, { css } from "styled-components";

import { DESKTOP_1366, MOBILE_380, MOBILE_575, DESKTOP_1200, TABLET_992, TABLET_768, TABLET_740, MOBILE_460 } from "./sizes";

import { COLORS } from "./colors";

export const SubTitle = styled.div`
  font-size: 35px;
  line-height: 42px;
  margin-top: 19px;
  font-weight: 600;
  color: ${COLORS.redTitle};

  @media (max-width: ${TABLET_992}px) {
    text-align: ${({ alignStart }) => (alignStart ? "start" : "center")};
  }

  @media (max-width: ${TABLET_740}px) {
    display: ${({ displayNone }) => displayNone && "none"};
  }
`;

export const UnderLineTitle = styled.div`
  width: 113px;
  height: 4px;
  margin: 18px 0 24px 0;
  background-color: ${({ isWhite }) =>
    isWhite ? COLORS.white : COLORS.redTitle};

  @media (max-width: ${TABLET_740}px) {
    display: ${({ displayNone }) => displayNone && "none"};
    margin: 18px 0 0 0;
  }
`;

export const BoldText = styled.div`
  font-weight: 600;
  display: contents;
`;

export const List = styled.ul`
  list-style: disc;
  margin-left: ${({ hasMarginLeft }) => (hasMarginLeft ? "16px" : "0")};

  li::marker {
    font-size: 1.2em;
  }
  li {
    font-size: 16px;
    line-height: 29px;
  }

  ${({ hasTwoColumns }) =>
    hasTwoColumns &&
    css`
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
      list-style-position: inside;
    `}

  @media (max-width: ${TABLET_740}px) {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
  }
`;

export const InternalWrapper = styled.div`
  max-width: 90%;
  margin: 0 2%;
  position: absolute;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 90%;
    margin: 0;
    flex: 1;
  }
  @media (max-width: ${TABLET_768}px) {
    max-width: 100%;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
  }
`;

export const MainContainer = styled.div`
	width: 100%;
  max-width: 1500px;
	margin: 0 auto;
	display: flex;
  flex-direction: column;
	overflow-x: hidden !important;

  @media (max-width: 1500px) {
		max-width: 1366px;
	}
	@media (max-width: ${DESKTOP_1366}px) {
		max-width: 1200px;
	}
  @media (max-width: ${DESKTOP_1200}px) {
		max-width: 992px;
	}
	@media (max-width: ${TABLET_992}px) {
		max-width: 770px;
	}
	@media (max-width: ${TABLET_768}px) {
		max-width: 500px;
	}
	@media (max-width: ${MOBILE_575}px) {
		max-width: 360px;
	}
	@media (max-width: ${MOBILE_380}px) {
		max-width: 300px;
	}
`;