import React, { useCallback, useState } from "react";

// Import Swiper React components
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";


import "./defaultStyles.css";

// import required modules
import { Pagination, Autoplay } from "swiper";

import {
  CredentialsBackground,
  CredentialsContainer,
  CredentialInfo,
  Line,
  BenefitsInfo,
  Title,
  InfoList,
  Item,
  ItemRight,
  ArrowLeft,
  ArrowRight,
  Picture,
  AmbulancePicture,
  CarrouselContent,
  ImageContainer,
  SwiperContainer,
} from "./styles";

import arrow from "../../images/arrow.svg";
import people from "../../images/people.png";
import ambulance from "../../images/ambulance.png";

const Credential = () => {
  const [swiperRef, setSwiperRef] = useState();
  SwiperCore.use([Navigation]);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  return (
    <CredentialsBackground>
      <ArrowLeft src={arrow} className="swiper-button-prev" onClick={handlePrevious} />
      <SwiperContainer
        loop
        autoplay={false}
        onSwiper={setSwiperRef}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        speed={700}
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={{
          210: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <CredentialsContainer>
            <CredentialInfo>
              <Title>BENEFITS</Title>
              <Line />
              <CarrouselContent></CarrouselContent>
              <InfoList>
                <Item>Competitive Pay</Item>
                <Item>
                  Flexible schedules are available: Part-time, Full-Time, or Per
                  Diem
                </Item>
                <Item>Shifts available 24/7</Item>
                <Item>Paycheck Advances</Item>
              </InfoList>
            </CredentialInfo>
            <ImageContainer>
              <Picture src={people} alt="emt staff" />
            </ImageContainer>
            <BenefitsInfo>
              <InfoList>
                <ItemRight>No cap on overtime</ItemRight>
                <ItemRight>Health benefits</ItemRight>
                <ItemRight>PTO</ItemRight>
                <ItemRight>Friendly and respectful environment</ItemRight>
              </InfoList>
            </BenefitsInfo>
          </CredentialsContainer>
        </SwiperSlide>
        <SwiperSlide>
          <CredentialsContainer>
            <CredentialInfo>
              <Title>EQUIPMENT</Title>
              <Line />
              <InfoList>
                <Item>New and like-new ambulances.</Item>
                <Item>Power stretchers.</Item>
                <Item>Uniforms.</Item>
              </InfoList>
            </CredentialInfo>
            <AmbulancePicture src={ambulance} alt="life ride ambulance" />
          </CredentialsContainer>
        </SwiperSlide>
      </SwiperContainer>
      <ArrowRight src={arrow} className="swiper-button-next" onClick={handleNext} />
    </CredentialsBackground>
  );
};

export default Credential;
