import React from "react";

import ImageOffice from "./assets/sec.png";


import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Title,
  Line,
  Text,
  TextContent,
  OfficeImage,
} from "./styles";

const OfficeStaff = () => {
  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <TextContent>
          <Title>OUR BACK OFFICE STAFF</Title>
          <Line />
          <Text>
            The team working at the office is at your disposal to make your job
            easier and fix any problem that might come up in your day-to-day.
          </Text>
        </TextContent>
      </TextContainer>
      <ImageContainer>
        <OfficeImage src={ImageOffice} alt="emt office staff" />
      </ImageContainer>
    </ContactContainer>
  );
};

export default OfficeStaff;
