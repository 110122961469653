import React, { useCallback, useState } from "react";

// Import Swiper React components
import { SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import "./defaultStyles.css";

// import required modules
import { Pagination, Autoplay } from "swiper";

import {
  CredentialsBackground,
  CredentialsContainer,
  CredentialInfo,
  Line,
  Title,
  InfoList,
  Item,
  ArrowRight,
  Picture,
  AmbulancePicture,
  CarrouselContent,
  ImageContainer,
  SwiperContainer,
} from "./styles";

import arrow from "../../images/arrow.svg";
import people from "../../images/people.png";
import ambulance from "../../images/ambulance.png";

const CredentialMobile = () => {
  const [swiperRef, setSwiperRef] = useState();
  SwiperCore.use([Navigation]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  return (
    <CredentialsBackground>
      <SwiperContainer
        loop={true}
        autoplay={false}
        onSwiper={setSwiperRef}
        speed={700}
        slidesPerView={1}
        spaceBetween={10}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        <ArrowRight src={arrow} onClick={handleNext} />
        <SwiperSlide>
          <CredentialsContainer>
            <CredentialInfo>
              <Title>BENEFITS</Title>
              <Line />
              <CarrouselContent></CarrouselContent>
              <InfoList>
                <Item>Competitive Pay</Item>
                <Item>
                  Flexible schedules are available: Part-time, Full-Time, or Per
                  Diem
                </Item>
                <Item>Shifts available 24/7</Item>
                <Item>Paycheck Advances</Item>
                <Item>Friendly and respectful environment</Item>
                <Item>No cap on overtime</Item>
                <Item>Health benefits</Item>
                <Item>PTO</Item>
              </InfoList>
            </CredentialInfo>
            <ImageContainer>
              <Picture src={people} alt="emt staff" />
            </ImageContainer>
          </CredentialsContainer>
        </SwiperSlide>
        <SwiperSlide>
          <CredentialsContainer>
            <CredentialInfo>
              <Title>EQUIPMENT</Title>
              <Line />
              <InfoList>
                <Item>New and like-new ambulances.</Item>
                <Item>Power stretchers.</Item>
                <Item>Uniforms.</Item>
              </InfoList>
            </CredentialInfo>
            <AmbulancePicture src={ambulance} alt="life ride ambulance" />
          </CredentialsContainer>
        </SwiperSlide>
      </SwiperContainer>
    </CredentialsBackground>
  );
};

export default CredentialMobile;
