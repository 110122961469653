import styled from "styled-components";
import { Swiper } from "swiper/react";

import { COLORS } from "../../styles/globals/colors";

import {
  DESKTOP_1200,
  TABLET_992,
  TABLET_800,
  TABLET_740,
  MOBILE_460,
  DESKTOP_1024,
} from "../../styles/globals/sizes";

export const CredentialsBackground = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  background: ${COLORS.gray95};
  padding: 75px 0 0;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: ${TABLET_992}px) {
    padding: 60px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    padding: 60px 20px 40px;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: none;
  }
`;


export const CredentialsContainer = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 945px;
    grid-template-columns: 600px auto;
  }
  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: 520px auto;
    grid-gap: 50px;
  }
  @media (max-width: ${TABLET_800}px) {
    grid-template-columns: none;
    grid-template-rows: 1fr auto;
    grid-gap: 20px;
  }
  @media (max-width: ${MOBILE_460}px) {
    grid-template-columns: 1fr;
  }
`

export const CredentialInfo = styled.div`
  color: ${COLORS.redTitle};
  text-align: left;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: left;

  @media (max-width: ${MOBILE_460}px) {
    display: block;
    text-align: left;
    width: 100%;
    max-width: 100%;
  }
`;

export const BenefitsInfo = styled.div`
  color: ${COLORS.redTitle};
  text-align: left;
  width: 40%;
  margin-top: 79px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MOBILE_460}px) {
    display: block;
    text-align: left;
    width: 100%;
    max-width: 100%;
  }
`;

export const CredentialText = styled.p`
  margin: 15px 0;
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.white};
  text-align: left;
  
  & span {
    font-weight: 500;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: ${TABLET_992}px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: ${TABLET_800}px) {
    margin: 0;
    width: 640px;
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: ${TABLET_740}px) {
    width: auto;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CredentialPicture = styled.picture`
  width: fit-content;
  justify-self: center;
  align-self: center;
  filter: grayscale(1) brightness(10);

  & > img, 
  & > source {
    height: 100%;

    @media (max-width: ${DESKTOP_1200}px) {
      height: 150px;
    }
    @media (max-width: ${TABLET_992}px) {
      height: 130px;
    }
    @media (max-width: ${TABLET_740}px) {
      height: 125px;
    }
  }
`;

export const Title = styled.h2`
  color: ${COLORS.redTitle};
  margin-bottom: 15px;
  font: 400 40px/50px Roboto;
  text-align: left;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 400 35px/40px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 0 10px;
    font: 400 32px/35px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    font: 400 37px/49px Roboto;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 400 30px/39px Roboto;
  }
`;

export const Line = styled.div`
  width: 149px;
  height: 4px;
  background: ${COLORS.redTitle} 0% 0% no-repeat padding-box;
  opacity: 1;
`;

export const InfoList = styled.ul`
  list-style: inside;

`;

export const Item = styled.li`
  width: 102%;
  margin: 25px 0px;
  font: normal normal 18px/24px Roboto;
  color: #464748;
  font-weight: 600;
  padding-left: 1.28571429em;
  text-indent: -1.28571429em;
`;

export const ItemRight = styled.li`
  width: 100%;
  margin: 25px 0px;
  font: normal normal 18px/24px Roboto;
  color: #464748;
  font-weight: 600;
  padding-left: 1.28571429em;
  text-indent: -1.28571429em;
`;

export const ArrowLeft = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  left: 3%;
  top: 45%;
  transform: rotate(180deg);
  z-index: 20;
`;

export const ArrowRight = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  right: 3%;
  top: 45%;
  z-index: 20;
`;

export const Picture = styled.img`
    object-fit: cover;
    width: 100%;
    position: absolute;
`;

export const AmbulancePicture = styled.img`
  width: 100%;
  max-width: 727px;
  height: auto;
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 578px;
  position: relative;
`;

export const CarrouselContent = styled.div`
  width: 90%;
  margin-top: 35px;
  .swiper-pagination-bullet-active {
    background-color: red !important;
  }
`;

export const SwiperContainer = styled(Swiper)`
  width: 100%;
  height: 100%;
`;
